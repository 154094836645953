import * as React from 'react';
import { Button, ArticleHeading2, ArticleHeading3, ModalWindow, ArticleParagraph2, useDeviceType } from '@cian/ui-kit';

import handsClapImg from './assets/hands-clap.png';
import * as styles from './UpcomingSaleSuccessModal.css';

interface IUpcomingSaleSuccessModalProps {
  open: boolean;
  onClose(): void;
}

export function UpcomingSaleSuccessModal({ open, onClose }: IUpcomingSaleSuccessModalProps) {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';

  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={styles['container']}>
        <img className={styles['image']} src={handsClapImg} alt="Hands clap" />
        <div className={styles['title']}>
          {isPhone ? (
            <ArticleHeading3 as="h3" textAlign="center">
              Вы оставили заявку!
            </ArticleHeading3>
          ) : (
            <ArticleHeading2 as="h3" textAlign="center">
              Вы оставили заявку!
            </ArticleHeading2>
          )}
        </div>
        <div className={styles['content']}>
          <ArticleParagraph2 color="gray60_100" textAlign="center">
            Застройщик свяжется с вами,
            <br />
            как только у него появится дополнительная
            <br />
            информация по старту продаж в ЖК
          </ArticleParagraph2>
        </div>
        <div className={styles['button-container']}>
          <Button onClick={onClose} size={isPhone ? 'M' : 'XS'} theme="fill_primary" type="button" fullWidth>
            Понятно
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
}
