import * as React from 'react';
import { Button, ArticleHeading3, ModalWindow, ArticleParagraph2, useDeviceType } from '@cian/ui-kit';

import cryingFaceImg from './assets/crying-face.png';
import sadFaceImg from './assets/sad-face.png';
import * as styles from './UpcomingSaleFailModal.css';

interface IUpcomingSaleFailModalProps {
  open: boolean;
  onClose(): void;
}

export function UpcomingSaleFailModal({ open, onClose }: IUpcomingSaleFailModalProps) {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';

  if (isPhone) {
    return (
      <ModalWindow title="Ой" open={open} onClose={onClose}>
        <div className={styles['container']}>
          <img className={styles['image-mobile']} src={sadFaceImg} alt="" />
          <div className={styles['title']}>
            <ArticleHeading3 as="h3" textAlign="center">
              Оставить заявку
              <br />
              не удалось
            </ArticleHeading3>
          </div>
          <div className={styles['content']}>
            <ArticleParagraph2 color="gray60_100" textAlign="center">
              Попробуйте позже
            </ArticleParagraph2>
          </div>
        </div>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={styles['container']}>
        <div className={styles['title']}>
          <ArticleHeading3 as="h3">
            Ой!
            <br />
            Оставить заявку не удалось
          </ArticleHeading3>
          <img className={styles['image']} src={cryingFaceImg} alt="" />
        </div>
        <div className={styles['content']}>
          <ArticleParagraph2>Попробуйте сделать это позже</ArticleParagraph2>
        </div>
        <div>
          <Button onClick={onClose} size="XS" theme="fill_primary" type="button">
            Хорошо
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
}
