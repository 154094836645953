import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStatusUpcomingSaleModal, createUpcomingSaleRequest } from '../../../../actions/upcomingSaleRequest';
import { UpcomingSaleRequestForm } from '../../../../components/UpcomingSaleRequestForm';
import { getAnalyticsUser, getProduct } from '../../../../selectors/analytics';
import { IRC } from '../../../../types/rc/rcs';
import { TThunkDispatch } from '../../../../types/redux';
import { EUpcomingSaleModalStatuses, IUpcomingSaleRequest } from '../../../../types/upcomingSaleRequest';
import { EUpcomingSaleTrackActions, trackUpcomingSaleRequest } from '../../tracking';

interface IUpcomingSaleRequestFormContainerProps {
  newbuilding: IRC;
  onClose(): void;
  open: boolean;
}

export function UpcomingSaleRequestFormContainer({
  newbuilding,
  onClose,
  open,
}: IUpcomingSaleRequestFormContainerProps) {
  const user = useSelector(getAnalyticsUser);
  const product = useSelector(getProduct(newbuilding.id));
  const dispatch: TThunkDispatch = useDispatch();

  const handleSubmit = React.useCallback(
    async (params: IUpcomingSaleRequest) => {
      const { gaLabel, id, geo } = newbuilding;
      const trackingParams = { gaLabel, phone: params.phone, product, user, name: params.userName };

      try {
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Send });
        await dispatch(createUpcomingSaleRequest({ ...params, newbuildingId: id, regionId: geo.regionId }));

        dispatch(setStatusUpcomingSaleModal(EUpcomingSaleModalStatuses.Success));
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Success });
      } catch {
        dispatch(setStatusUpcomingSaleModal(EUpcomingSaleModalStatuses.Error));
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Fail });
      }
    },
    [dispatch, newbuilding, product, user],
  );

  return (
    <UpcomingSaleRequestForm
      open={open}
      onClose={onClose}
      newbuildingTitle={newbuilding.name}
      onSubmit={handleSubmit}
    />
  );
}
