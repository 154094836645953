import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeUpcomingSaleModal } from '../../actions/upcomingSaleRequest';
import { UpcomingSaleFailModal } from '../../components/UpcomingSaleFailModal';
import { UpcomingSaleSuccessModal } from '../../components/UpcomingSaleSuccessModal';
import { getUpcomingSaleRequestStatus, getUpcomingSaleRequestNewbuilding } from '../../selectors/upcomingSaleRequest';
import { EUpcomingSaleModalStatuses } from '../../types/upcomingSaleRequest';
import { UpcomingSaleRequestFormContainer } from './parts/UpcomingSaleRequestFormContainer';

// eslint-disable-next-line import/no-default-export
export default function UpcomingSaleRequestContainer() {
  const newbuilding = useSelector(getUpcomingSaleRequestNewbuilding);
  const status = useSelector(getUpcomingSaleRequestStatus);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  const handleCloseModal = React.useCallback(() => dispatch(closeUpcomingSaleModal()), [dispatch]);

  if (!newbuilding) {
    return null;
  }

  return (
    <>
      <UpcomingSaleRequestFormContainer
        open={status === EUpcomingSaleModalStatuses.Form}
        onClose={handleCloseModal}
        newbuilding={newbuilding}
      />
      <UpcomingSaleSuccessModal open={status === EUpcomingSaleModalStatuses.Success} onClose={handleCloseModal} />
      <UpcomingSaleFailModal open={status === EUpcomingSaleModalStatuses.Error} onClose={handleCloseModal} />
    </>
  );
}
