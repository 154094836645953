import * as React from 'react';
import {
  Button,
  FormField,
  ArticleHeading2,
  ArticleHeading3,
  Input,
  MaskedInput,
  ModalWindow,
  ArticleParagraph1,
  ArticleParagraph3,
  Responsive,
  useDeviceType,
} from '@cian/ui-kit';

import { ClientComponent } from '../ClientComponent';
import { IValidationErrors, IUpcomingSaleRequest } from '../../types/upcomingSaleRequest';
import { prepareFormValues, validateFormValues } from './utils';

import * as styles from './UpcomingSaleRequestForm.css';

export interface IUpcomingSaleRequestFormProps {
  newbuildingTitle: string;
  onClose(): void;
  onSubmit(data: IUpcomingSaleRequest): void | Promise<void>;
  open: boolean;
}

const initialValues = {
  email: '',
  phone: '',
  userName: '',
};

export function UpcomingSaleRequestForm({ newbuildingTitle, onClose, onSubmit, open }: IUpcomingSaleRequestFormProps) {
  const [values, setValues] = React.useState<IUpcomingSaleRequest>({ ...initialValues });
  const [errors, setErrors] = React.useState<IValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const deviceType = useDeviceType();

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: undefined }));
  }, []);

  const handleMaskedInputChange = React.useCallback((value: string) => {
    setValues(prev => ({ ...prev, phone: value }));
    setErrors(prev => ({ ...prev, phone: undefined }));
  }, []);

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const preparedValues = prepareFormValues(values);
      const validationErrors = validateFormValues(preparedValues);

      if (Object.values(validationErrors).some(err => err)) {
        setErrors(validationErrors);

        return;
      }

      setIsSubmitting(true);
      await onSubmit(preparedValues);
      setValues({ ...initialValues });
      setIsSubmitting(false);
    },
    [values, onSubmit],
  );

  const handleClose = React.useCallback(() => {
    setValues({ ...initialValues });
    setErrors({});
    onClose();
  }, [onClose]);

  const isPhone = deviceType === 'phone';

  return (
    <ModalWindow
      open={open}
      onClose={handleClose}
      title={isPhone ? 'Заявка' : undefined}
      height={isPhone ? '100%' : undefined}
    >
      <div className={styles['container']}>
        <Responsive phone>
          <div className={styles['title']}>
            <ArticleHeading3 as="h2">{newbuildingTitle}</ArticleHeading3>
          </div>
        </Responsive>
        <Responsive tablet desktop>
          <div className={styles['title']}>
            <ArticleHeading2 as="h2">Заявка</ArticleHeading2>
          </div>
        </Responsive>
        <div className={styles['block']}>
          <ArticleParagraph1>
            Застройщик свяжется с вами, как только у него появится дополнительная информация по старту продаж в ЖК
          </ArticleParagraph1>
        </div>
        <form className={styles['form']} onSubmit={handleSubmit}>
          <div className={styles['block']}>
            <FormField label="Имя фамилия" errorMessage={errors.userName} withoutMargin>
              <Input
                name="userName"
                onChange={handleChange}
                value={values.userName}
                invalid={!!errors.userName}
                disabled={isSubmitting}
                placeholder="Иван Иванов"
                size="M"
              />
            </FormField>
          </div>
          <div className={styles['block']}>
            <FormField label="Телефон" errorMessage={errors.phone} withoutMargin>
              <ClientComponent
                replaceNode={
                  <Input
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    placeholder="+7"
                    size="M"
                    inputMode="tel"
                  />
                }
              >
                <MaskedInput
                  name="phone"
                  mask="+7 000 000-00-00"
                  onChange={handleMaskedInputChange}
                  value={values.phone}
                  invalid={!!errors.phone}
                  disabled={isSubmitting}
                  placeholder="+7"
                  size="M"
                  inputMode="tel"
                  lazy
                />
              </ClientComponent>
            </FormField>
          </div>
          <div className={styles['block']}>
            <FormField label="E-mail" errorMessage={errors.email} withoutMargin>
              <Input
                name="email"
                onChange={handleChange}
                value={values.email}
                invalid={!!errors.email}
                disabled={isSubmitting}
                placeholder="example@mail.com"
                size="M"
              />
            </FormField>
          </div>
          <div className={styles['submit']}>
            <Button
              loading={isSubmitting}
              type="submit"
              theme="fill_primary"
              fullWidth={isPhone}
              data-testid="UpcomingSaleFormSubmitButton"
            >
              Отправить
            </Button>
          </div>
        </form>
        <ArticleParagraph3 color="gray40_100" textAlign={isPhone ? 'center' : 'left'}>
          Нажимая на кнопку, вы соглашаетесь на обработку персональных данных в соответствии{' '}
          <a className={styles['link']} href="/legal-documents/politika_konfidencialnosti_0/">
            политикой конфиденциальности
          </a>
        </ArticleParagraph3>
      </div>
    </ModalWindow>
  );
}
